<!--
 * @Author: your name
 * @Date: 2021-12-06 14:40:27
 * @LastEditTime: 2021-12-09 19:33:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ksh-factory-vue/src/bizComponents/AccountUserRecallModel.vue
-->

<template>
  <div>
    <el-dialog title="积分撤回" width="35%" :visible.sync="visible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" destroy-on-close>
      <el-row type="flex" justify="space-between" class="desc-text">
        <span>部门名称：</span>
        <span class="flex-box align-right">{{ item.orgName || '-' }}</span>
      </el-row>
      <el-row type="flex" justify="space-between" align="middle" class="desc-text">
        <span>负责人：</span>
        <span class="flex-box align-right">{{ item.name || '-' }}</span>
      </el-row>
      <el-row type="flex" justify="space-between" align="middle" class="desc-text">
        <span>帐号：</span>
        <span class="flex-box align-right">{{ item.username || '-' }}</span>
      </el-row>
      <el-divider class="w-h"></el-divider>
      <el-row type="flex" justify="space-between" align="middle" class="desc-text">
        <span>当前可撤回余额：</span>
        <span class="flex-box align-right">{{ canRecallMoney }}</span>
      </el-row>
      <el-row type="flex" justify="space-between" align="middle" class="desc-text">
        <span>请输入撤回金额：</span>
        <el-input-number v-model="money" :precision="0" :max="canRecallMoney" :min="0" placeholder="请输入撤回金额"></el-input-number>
      </el-row>
      <el-row slot="footer" type="flex" justify="center">
        <el-button plain @click="onClose">取消</el-button>
        <el-button type="primary" @click="onSave" :loading="loading">确认</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AccountUserRecallModal',
  data() {
    return {
      money: '',
      accountType: 1,
      canRecallMoney: 0,
      loading: false,
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  watch: {
    visible(show) {
      if (show) {
        let req = {
          orgIdList: [this.item.orgId],
          type: this.accountType,
        }
        this.getOrgAccountAmount(req).then((res) => {
          this.canRecallMoney = res[this.item.orgId]
        })
      }
    },
  },
  computed: {
    ...mapGetters(['factoryId']),
  },
  methods: {
    ...mapActions(['onRecallUserAccount', 'getOrgAccountAmount']),
    onChange(val) {},
    onValid() {
      let valid = true
      if (!this.money) {
        valid = false
        this.$message.error('请输入撤回积分')
      }
      return valid
    },
    onSave() {
      if (!this.onValid()) return
      this.loading = true
      let req = {
        childOrgId: this.item.orgId,
        giftCount: this.money,
      }
      this.onRecallUserAccount(req).then(
        (res) => {
          this.loading = false
          this.$emit('onSubmit', [this.factoryId, this.item.orgId])
          this.onClose()
          this.$message.success('积分撤回成功')
        },
        (rea) => {
          this.$message.error(rea.message)
          this.loading = false
        }
      )
    },
    onClose() {
      this.canRecallMoney = 0
      this.money = ''
      this.$emit('onClose')
    },
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.certify-image {
  height: 160px;
  width: 160px;
  background-color: #f6f7fb;
  margin-top: 10px;
}
.desc-text {
  color: #272e40;
  &:nth-of-type(n + 2) {
    margin-top: 10px;
  }
}
.el-divider--horizontal {
  margin: 15px 0;
}
</style>
