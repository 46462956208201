<template>
  <div>
    <page-main v-loading="loading" back title>
      <el-button
        v-if="!isMobile"
        type="primary"
        class="export-data"
        :loading="exportLoading"
        @click="exportData"
      >
        导出数据
      </el-button>
      <el-table
        :data="initOrgTreeList"
        :indent="28"
        row-key="orgId"
        stripe
        lazy
        :expand-row-keys="defaultOpenArr"
        :load="onOpenOrgload"
        :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
        class="tree-table"
      >
        <el-table-column
          prop="orgName"
          width="auto"
          show-overflow-tooltip
          :min-width="treeColumnMinWidth"
          label="组织"
        >
          <template slot-scope="scope">
            <div class="org-name-box">
              <el-tooltip
                v-if="scope.row.orgStatus !== 1"
                effect="light"
                content="该组织已被冻结"
                placement="bottom"
              >
                <img src="@/assets/nav/forbid.png" alt="">
              </el-tooltip>
              {{ scope.row.orgName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          min-width="120"
          align="center"
          label="姓名"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="username"
          min-width="210"
          align="center"
          label="帐号"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.username || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="giftCount"
          align="center"
          min-width="180"
          label="账户实时可支配余额"
        >
          <template slot-scope="scope">
            <el-row type="flex" align="middle" justify="center">
              <span class="t-wid">{{ scope.row.giftCount === null ? '—' : amountListObj[scope.row.orgId] || scope.row.giftCount }}</span>
              <i v-if="scope.row.giftCount !== null" class="el-icon-refresh text-info-link font-s16 mgn-l14" @click="onRefreshAmount([scope.row.orgId])" />
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="90"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.orgId === factoryId">—</span>
            <el-link
              v-else
              type="primary"
              :disabled="scope.row.orgStatus !== 1"
              :underline="false"
              @click.stop="onShowTransferModal(scope.row)"
            >
              转账
            </el-link>
            <el-link
              v-if="scope.row.isDisplayRecallBtn"
              type="primary"
              :disabled="scope.row.orgStatus !== 1"
              :underline="false"
              class="mgn-l14"
              @click.stop="onShowRecallModal(scope.row)"
            >
              撤回
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" class="t-p">
        <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="pageNo"
          :total="totalCount"
          @current-change="onChange"
        />
      </el-row>
    </page-main>
    <account-user-transfer-modal
      :visible="showTransferModal"
      :item="selectedUser"
      @onSubmit="onRefreshAmount"
      @onClose="onClose"
    />
    <account-user-recall-modal
      :visible="showRecallModal"
      :item="selectedUser"
      @onSubmit="onRefreshAmount"
      @onClose="onClose"
    />
  </div>
</template>
<script>
import AccountUserTransferModal from '@/bizComponents/AccountUserTransferModal'
import AccountUserRecallModal from '@/bizComponents/AccountUserRecallModal'
import { mapActions, mapGetters } from 'vuex'
import { isMobile } from '@/utils/util.js'
export default {
  name: 'AccountUserTransfer',
  components: {
    AccountUserTransferModal,
    AccountUserRecallModal
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      selectedOrgId: '',
      showRecallModal: false, // 撤回弹窗
      loading: false,
      showTransferModal: false,
      selectedUser: {},
      amountListObj: {},
      exportLoading: false,
      isMobile: isMobile(),
      treeColumnMinWidth: 120
    }
  },
  computed: {
    ...mapGetters(['orgAccountTreeList', 'factoryId']),
    defaultOpenArr() {
      return this.orgAccountTreeList.map(item => item.orgId)
    },
    initOrgTreeList() {
      const list = []
      list.push({ ...this.orgAccountTreeList[0], hasChildren: null })
      return list
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['getOrgAccountList', 'getOrgAccountAmount', 'getOrgAccountChildrenList']),
    init() {
      this.loading = true
      this.getOrgAccountList().then(
        () => {
          this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    },
    onOpenOrgload(tree, treeNode, resolve) {
      this.getOrgAccountChildrenList({ orgId: tree.orgId, accountType: 1 }).then(res => {
        resolve(res)
      }).finally(() => {
        const fastColDom = document.querySelectorAll('.tree-table .el-table__body-wrapper tbody tr td:nth-child(1) .cell')
        const widthArr = []
        if (fastColDom.length === 0) return
        for (let i = 0; i < fastColDom.length; i++) {
          const dom = fastColDom[i]
          // 找到 dom 的子元素 .el-table__indent 的宽度
          const indent = dom.querySelector('.el-table__indent')
          const indentWidth = indent ? indent.clientWidth : 0
          // 找到 dom 的子元素 .el-table__expand-icon 的宽度
          const expandIcon = dom.querySelector('.el-table__expand-icon')
          const expandIconWidth = expandIcon ? expandIcon.clientWidth : 0
          // 找到 dom 的子元素 .org-name-box 的宽度
          const orgNameBox = dom.querySelector('.org-name-box')
          const orgNameBoxWidth = orgNameBox ? orgNameBox.clientWidth : 0

          widthArr.push(indentWidth + expandIconWidth + orgNameBoxWidth)
        }
        this.treeColumnMinWidth = Math.max(...widthArr) + 20
      })
    },
    onChangeExpand(row, expanded) {
      if (expanded) {
        // 掌开状态
        let amountList = row.giftCount !== null ? [row.orgId] : []
        if (row.child) {
          row.child.map(v => {
            amountList = v.giftCount !== null ? [...amountList, v.orgId] : amountList
          })
        }
        this.onRefreshAmount(amountList)
      }
    },
    onRefreshAmount(orgIdList = []) {
      const req = {
        orgIdList,
        type: 1
      }
      this.getOrgAccountAmount(req).then(res => {
        this.amountListObj = { ...this.amountListObj, ...res }
        this.$store.commit('UPDATE_ORG_USER_ACCOUNT', res)
      })
    },
    onShowTransferModal(item) {
      this.showTransferModal = true
      this.selectedUser = item
    },
    onShowRecallModal(item) {
      this.showRecallModal = true
      this.selectedUser = item
    },
    onClose() {
      this.showTransferModal = false
      this.showRecallModal = false
      this.selectedUser = {}
    },
    onChange(page) {
      this.pageNo = page
    },
    exportData() {
      this.exportLoading = true
      this.$axios
        .get(this.$API.integrationAccountDownload, {
          params: {
            accountType: 1
          }
        })
        .then(({ data }) => {
          this.$message.success(data.data)
        })
        .catch(error => {
          this.$message.error(error.message)
        })
        .finally(() => {
          this.exportLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.export-data {
  position: absolute;
  top: 3.5px;
  right: 10px;
}
.container {
  padding: 0;
  .lay-box {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .t-p {
    padding-top: 15px;
  }
}
.tree-table {
  ::v-deep tbody {
    tr {
      td:nth-child(1) {
        .cell {
          display: flex;
          .org-name-box {
            display: inline;
            img {
              vertical-align: middle;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
