<!--
 * @Author: your name
 * @Date: 2020-12-22 13:57:17
 * @LastEditTime: 2022-03-28 10:35:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/bizComponents/PaymentLimitModal.vue
-->
<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    class="factory-modal"
    width="540px"
    :before-close="onClose"
  >
    <div class="title text-ct">安全提醒</div>
    <div class="content text-ct">
      为了提升账号和资金安全，操作金额大于限额时，需要{{ limitType === 'sms'? '手机验证码': '微信扫码' }}验证，您也可以去账号中心设置限额。当前账号尚未绑定{{ limitType === 'sms'? '手机号': '微信' }}，请绑定后重新操作。
      <div class="hint pointer" @click="onJumpHelp">为何要支付验证？</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="onClose">修改支付验证限额</el-button> -->
      <el-button type="primary" @click="onSubmit">前往修改</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {isMobile} from '@/utils/util.js'
export default {
  name: 'PaymentLimitModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    limitType: {
      type: String,
      default: 'wechat'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([]),
    onClose() {
      this.$emit('onClose')
    },
    onSubmit() {
      if (isMobile()) {
        this.$message({
          message: '请在PC端操作',
          type: 'warning'
        })
        return
      }
      this.$router.push({name: 'SafeCenter'})
    },
    onJumpHelp() {
      const url = this.$router.resolve('/help/detail/6f5e18a547a0c01aa662dfc2a43854bc')
      window.open(url.href, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
$color-primary: #3d61e3;
.el-button {
  width: 150px;
}
.factory-modal ::v-deep .el-dialog__header {
  display: none;
}
.factory-modal ::v-deep .el-dialog__body {
  padding: 0;
  max-height: 380px;
  display: flex;
  flex-direction: column;
}
.factory-modal ::v-deep .el-dialog__footer {
  border-top: 1px solid #e9eaeb;
  padding: 30px 70px;
  display: flex;
  justify-content: center;
}
.title {
  color: #272e40;
  font-size: 24px;
  flex-shrink: 0;
  padding: 30px 70px 30px;
}
.content {
  padding: 0 70px;
  color: #272e40;
}
.hint {
  margin: 20px 0;
  color: #adadad;
}
</style>
