<template>
  <el-dialog
    :visible.sync="visible"
    title="大额支付验证"
    class="factory-modal"
    width="25%"
    :before-close="onClose"
  >
    <div class="text-ct">
      <!-- 手机号隐藏中间四位 -->
      <div class="mgn-t30 mgn-b20">点击发送验证码至手机号：{{ mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') }}</div>
      <div class="mgn-b10">
        <el-input
          v-model="sms"
          class="sms-btn"
          placeholder="请输入短信验证码"
        />
        <el-button
          v-if="countDownTime == 0"
          type="primary"
          plain
          :disable="isSend"
          @click="sendSms"
        >
          {{ isSend ? '发送中' : '发送验证码' }}
        </el-button>
        <el-button
          v-else
          disabled
          type="info"
        >
          重新发送{{ countDownTime }}
        </el-button>
      </div>
      <div class="sms-voice-btn color-primary pointer" @click="sendVoice">收不到验证码？点击语音验证</div>
      <el-button
        :disabled="!sms || !isSendSms"
        :type="sms && isSendSms ? 'primary' : 'info'"
        class="pay-btn"
        @click="onSubmit"
      >
        确认支付
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'CheckSmsModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      timer: null,
      mobile: '',
      sms: '',
      countDownTime: 0,
      isSend: false,
      isVoice: false,
      showVoice: false,
      smsTimer: null,
      isSendSms: false
    }
  },
  watch: {
    visible(open) {
      if (open) {
        this.sms = ''
        this.countDownTime = 0
        this.isSend = false
        this.isVoice = false
        this.showVoice = false
        this.smsTimer = null
        this.isSendSms = false
        this.getPhoneNum()
      } else {
        this.clearSmsTimer()
      }
    }
  },
  methods: {
    ...mapActions(['getSmsCode', 'checkCaptcha']),

    onClose() {
      this.$emit('onClose')
    },
    getPhoneNum() {
      this.$axios.get(this.$API.getAccountInfo)
        .then(res => {
          // 隐藏手机号中间四位
          this.mobile = res.data.bindMobile
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },
    sendSmsPre() {
      // 发送验证码前 前端不做人机校验 通过code===4000来显示人机校验
      this.createCaptcha(this.sendSms)
    },
    sendSms(data) {
      this.isSendSms = true
      this.isVoice = false
      this.getVerifyCode(data)
    },
    sendVoicePre() {
      // 发送验证码前 前端不做人机校验 通过code===4000来显示人机校验
      if (!this.ruleSMSForm.mobile) return this.$message.error('请输入手机号')
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      this.createCaptcha(this.sendVoice)
    },
    sendVoice(data) {
      if (this.isVoice) return
      this.isVoice = true
      this.isSendSms = true
      this.clearSmsTimer()
      this.getVerifyCode(data)
    },
    getVerifyCode() {
      if (this.countDownTime) return
      if (this.isSend) {
        return
      }
      this.isSend = true
      const req = {
        mobile: this.mobile,
        clientType: 30, // 1 医生端 2 代表端 30 厂家组织核心
        smsType: 115, // 101 注册验证码 102 重置密码 103 更换手机号 104 推流端验证码 105厂家端登录验证码 106厂家端绑定手机获取验证码 107找回密码获取验证码 115大额支付验证
        isVoice: this.isVoice
      }
      this.getSmsCode(req)
        .then(() => {
          this.$message.success('验证码已发送')
          this.isSend = false
          this.doIntervalCount()
        })
        .catch(rea => {
          if (rea.code === 4000) {
            const func = this.isVoice ? this.sendVoice : this.sendSms
            this.isSend = false
            this.isVoice = false
            this.createCaptcha(func)
          } else {
            this.isSend = false
            this.isVoice = false
            this.$message.error(rea.message)
          }
        })
    },
    doIntervalCount() {
      this.countDownTime = 60
      this.smsTimer = setInterval(() => {
        this.countDownTime--
        if (this.countDownTime < 30) {
          this.showVoice = true
        }
        if (this.countDownTime < 1) {
          this.isVoice = false
          this.clearSmsTimer()
        }
      }, 1000)
    },
    clearSmsTimer() {
      this.countDownTime = 0
      this.smsTimer && clearInterval(this.smsTimer)
      this.smsTimer = null
    },
    // 创建腾讯云验证 传入回调
    createCaptcha(cb, faii) {
      const captcha1 = new window.TencentCaptcha(this.captchaAppId, res => {
        if (res.ret === 0) {
          // 成功 处理回调
          this.onCheckCaptcha(res).then(() => {
            cb && cb(res)
          })
        } else if (res.ret === 2) {
          // 失败
          faii && faii()
        }
      })
      captcha1.show()
    },
    onCheckCaptcha(data) {
      const req = {
        randStr: data.randstr,
        ticket: data.ticket,
        mobile: this.mobile
      }
      return new Promise((resolve, reject) => {
        this.checkCaptcha(req).then(
          () => {
            resolve()
          },
          rea => {
            reject()
            this.$message.error(rea.message)
          }
        )
      })
    },
    onSubmit() {
      if (!this.sms) return this.$message.error('请输入验证码')
      this.isSendSms = true
      this.clearSmsTimer()
      this.$emit('onSuccess', this.sms)
    }
  }
}
</script>
<style lang="scss" scoped>
.sms-btn {
  width: 130px;
  margin-right: 10px;
}
.sms-voice-btn {
  margin-left: -25%;
  margin-bottom: 20px;
  font-size: 12px;
  text-decoration: underline;
}
.pay-btn {
  width: 90%;
  margin-bottom: 10px;
}
 </style>
