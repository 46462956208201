<!--
 * @Author: your name
 * @Date: 2020-12-22 14:03:56
 * @LastEditTime: 2022-03-28 10:37:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/bizComponents/PaymentCaptchaModal.vue
-->
<template>
  <el-dialog  :visible.sync="visible" :show-close='false' class="factory-modal" width='540px' :before-close="onClose">
		<div class="title text-ct">安全提醒</div>
		<div class="flex level-center vertical-center">
			<div class="border-image" >
				<img :src="paymentCodeImage.qrCodeUrl" alt="">
			</div>
		</div>
		<div class="content text-ct">
			为了账号资金安全，请用账号绑定的微信扫码验证
			<div class="hint pointer" @click="onJumpHelp">为何要支付验证？</div>
		</div>
  </el-dialog>
</template>
<script>
import {mapActions,mapGetters} from 'vuex'
export default {
  name: 'PaymentCaptchaModal',
  data() {
    return {
			loading:false,
			timer:null
    }
  },
  props:['visible','codeType','checkValue'],
  computed: {
		...mapGetters(['paymentCodeImage'])
  },
  methods: {
    ...mapActions(['getPaymentCodeImage','checkPaymentCodeResult']),
    onClose () {
			this.$emit('onClose')
		},
		onJumpHelp(){
			let url = this.$router.resolve(`/help/detail/6f5e18a547a0c01aa662dfc2a43854bc`)
      window.open(url.href, '_blank')
		},
		init(){
			this.clearTimer()
			let req = {
				codeType:this.codeType,
				checkValue:this.checkValue
			}
			this.getPaymentCodeImage(req).then(res=>{
				this.startCheckInterval()
			},rea=>{
				this.$message.error(rea.message)
			})
		},
		startCheckInterval(){
			this.timer = setInterval(()=>{
				let req = {
					loopKey:this.paymentCodeImage.loopKey
				}
				this.checkPaymentCodeResult(req).then(res=>{
					// 状态：SUCCESS 扫码成功，NO_USER 没找到用户；WAIT_FOR_SCAN 等待扫码; QRCODE_EXPIRED 二维码过期; NO_PERMISSION 不是医生、没认证、没直播等 ; NOT_BIND 未绑定账号；NOT_THIS_WECHAT 不是当前微信
					if(res.status === 'SUCCESS'){
						if(res.data){
							this.$emit('onSuccess',res.data)
							this.$emit('onClose')
						}
					}else if(res.status === 'QRCODE_EXPIRED'){
							this.init()
					}else if(res.status === 'NOT_THIS_WECHAT' || res.status === 'NO_USER'){
						this.$message.error('扫码微信不是当前账号绑定的微信，请更换微信重新扫码')
						this.init()
					}

				},rea=>{
					this.$message.error(rea.message)
				})
			},2000)
		},
		clearTimer(){
			this.timer && clearInterval(this.timer)
			this.timer = null
		}
  },
  watch: {
    visible (open) {
      if (open) {
				this.init()
      }else{
				this.clearTimer()
			}
    }
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
$color-primary: #3D61E3;
.el-button {
  width: 150px;
}
.factory-modal ::v-deep .el-dialog__header {
  display: none;
}
.factory-modal ::v-deep .el-dialog__body {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.factory-modal ::v-deep .el-dialog__footer {
  border-top: 1px solid #E9EAEB;
  padding: 30px 70px;
  display: flex;
  justify-content: center;
}
.title {
  color: #272E40;
  font-size: 24px;
  flex-shrink: 0;
  padding: 30px 70px 30px;
}
.content {
  padding: 0 70px;
  color: #272E40;
}
.hint {
  margin: 20px 0;
  color: #adadad;
}
.border-image {
  border: 1px solid #E9EAEB;
  padding: 10px;
  margin-bottom: 20px;
  img {
    width: 200px;
    height: 200px;
  }
}
</style>
